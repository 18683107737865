import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import LayoutComponent from "../components/global/layout";
import LatestBlogPostsComponent from "../components/blog/latest-posts";
import SEOComponent from "../components/global/seo";

const IndexPage = ({ data }) => {
  const avatarImage = getImage(data.imageSharp)

  return (
    <LayoutComponent>
      <SEOComponent title="Surinder Bhomra | Coder and Blogger based in Oxford" shareImageIsLarge={true} />
      <div className="divide-y divide-gray-200 dark:divide-gray-700 mt-8 md:mt-16">
        <div className="my-4 pt-6 pb-8 space-y-2 md:space-y-5 xl:grid xl:grid-cols-3">
          <div className="xl:col-span-2 pr-8">
            <p
              className="pb-6 text-3xl font-extrabold leading-9 tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl sm:leading-10 md:text-6xl md:leading-14">
              Hi, I'm Surinder! <i className="twa twa-waving-hand"></i>
            </p>
            <div className="text-lg leading-8 text-gray-600 dark:text-gray-400">
              <h1 className="text-neutral-900 dark:text-neutral-200">
                A Web and Software Developer based in Oxford delivering CMS and custom build solutions to a wide variety of high profile clients.
              </h1>
              <p className="mt-4 mb-8">
                With around 15 years experience in the industry, I am just as passionate about coding as I was from the very first console app I created many moons ago that read out: "Hello World!".
              </p>
              <p className="my-8">
                I hope you enjoy your stay.
              </p>
            </div>
          </div>
          <div className="hidden xl:block">
            <div className="block items-center justify-between">
              <div className="flex justify-center items-center mb-3">
                <GatsbyImage image={avatarImage} className="gatsby-image-rounded" alt="Surinder Bhomra" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <LatestBlogPostsComponent data={data} />
    </LayoutComponent>
  )
}

export const query = graphql`
  query latestBlogPostsList {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {frontmatter: {draft: {eq: false}}, fields: {type: {eq: "post"}}},
      limit: 5
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            summary
            date
          }
        }
      }
    }
    imageSharp(fluid: {originalName: {eq: "logo-me.jpg"}}) {
      fluid {
        src
        originalName
      }
      gatsbyImageData(width: 500, placeholder: BLURRED)
    }
  }
`

export default IndexPage
